import s from "./style.module.less"
import React from "react"

export default class Euleschildkrote extends React.Component {
  constructor(props) {
    super(props)
    this.state = { piekses: [] }
  }
  addPieks(target) {
    const newPiekses = this.state.piekses
    newPiekses.push({ target: target, id: Math.random() })
    this.setState({
      piekses: newPiekses,
    })

    setTimeout(() => {
      const newPiekses = this.state.piekses
      newPiekses.shift()
      this.setState({
        piekses: newPiekses,
      })
    }, 2500)
  }
  render() {
    return (
      <div className={s.container}>
        <PieksButton onClick={() => this.addPieks("👈")}>🦉</PieksButton>
        <PieksButton onClick={() => this.addPieks("👉")}>🐢</PieksButton>
        <div className={s.piekses}>
          {this.state.piekses.map(pieks => {
            return (
              <div
                key={pieks.id}
                className={`${s.pieks} ${s["pieks" + pieks.target]}`}
              >
                {pieks.target}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

function PieksButton({ children, onClick }) {
  return (
    <button onClick={onClick} className={s.button}>
      {children}
    </button>
  )
}
